/* form */
.form-control:focus {
    border-color: #f18b27 !important;
    box-shadow: 0 0 0 0.2rem rgba(241, 139, 39, 25%) !important;
}

.css-ahj2mt-MuiTypography-root {
    font-family: "Poppins", sans-serif !important;
}

.form{
  border: none !important;
}

.form select {
  width: 100% !important;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}
/* checkbox ------------------------------------------------------------------------------------------->*/
.main {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default checkbox */
  .main input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border-radius:5px;
    background-color: #fff;
    border: 2px solid #888;
  }
  
  /* On mouse-over, add a grey background color */
  /* .main:hover input ~ .checkmark {
    background-color: #fbdead;
  } */
  
  /* When the checkbox is checked, add a blue background */
  .main input:checked ~ .checkmark {
    background-color: #f18b27;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .main input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .main .checkmark:after {
    left: 6px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

