.accodion-container {
  box-shadow: 0px 7px 50px rgba(15, 14, 54, 0.05);
  border-radius: 5px;
  padding: 35px;
  /* font-family: Inter; */
}

.accodion-container .accordion-item {
  border-color: #96979ced;
}
.accodion-container .accordion-body {
  padding: 1rem 0;
}
.accodion-container .accordion-header {
  margin: 0.5rem 0;
}
.accodion-container .accordion-header > .accordion-button {
  text-transform: capitalize;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 32px;
  color: #04231a;
  padding: 1rem 0;
}
.accodion-container .accordion-header .accordion-button:focus {
  background-color: #fff;
  color: #f27837;
  border: none;
  outline: 0px;
  box-shadow: none;
}
.accodion-container .accordion-header .accordion-button:not(.collapsed) {
  background-color: transparent;
  border-bottom: none !important;
}
.accodion-container .accordion-body > p {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 29px;
  color: #717477;
}
.accodion-container .accordion-body > p > b {
  color: black;
}

.faq-container{
  margin-top: 100px;
  margin-bottom: 60px;
  overflow-x: hidden;
}
.accordion-button::after{
  border-bottom: 0px !important;
}